import axios from 'axios'
import config from '@/config/config.app.json'
import router from '@/router'
import EventBus from '@/plugins/lib/EventBus';
import Vue from 'vue'

export default class {
  
  constructor() {
    axios.interceptors.response.use((response) => {
      EventBus.$emit('rhRequest.intercept', response)
      return response;
    }, function (error) {
      return Promise.reject(error);
    });
  }
  
  getDefaultOptions(method) {
    return {
      method: method,
      host: config.portalApi,
      endpoint: '',
      responseType: 'json',
      serializer: 'json',
      timeout: config.requestTimeoutSlow,
      logout: true
    };
  }

  getRequestHeaders() {
    return {
      "App-Version": config.configVersion,
      Authuser: localStorage.user,
      Authtoken: localStorage.token
    }
  }
  
  sendGet(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('get')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }
  sendPost(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('post')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }
  sendPut(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('put')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }
  sendPatch(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('patch')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }
  sendDelete(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('delete')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }

  sendRequest(options, successCallback, errorCallback) {
    let self = this
    options.url = options.host + options.endpoint;
    options.headers = this.getRequestHeaders();

    if (!errorCallback) {
      errorCallback = self.defaultErrorCb
    }

    axios(options).then(
        successCallback,
        (response) => this.handleErrorResponse(response, errorCallback, options)
    )
  }
  
  defaultErrorCb(resp) {
    console.error(resp.message)
  }

  handleErrorResponse(response, errorCallback, options) {
    errorCallback(response)
    if (response?.response?.status === 401 && options.logout) {
      console.error('Session invalid - log-out user')
      Vue.prototype.$rhAuth.logout(() => {
        router.push({
          name: 'login',
          params: {wasUnauthorized: 'true'}
        }).then(() => {})
      })
    }
  }
}
