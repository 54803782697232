<template>
  <fullscreen-overlay-frame :title="$t('location-dialog.title').toString()"
                            icon="fmd_good"
                            color="primary"
                            centered
                            closable
                            maximizable
                            @close="close"
                            @maximize="maximize"
                            @minimize="minimize">
    <template v-slot:content>
      <v-card>
        <v-card-text>
          <!-- v-form for Location
         Inputs: Street, housenumber, zip, City, Country (Dropdown)-->
          <v-form ref="locationForm" v-model="valid" class="px-2 pt-6">
            <!-- Street -->
            <v-text-field
                outlined
                v-model="location.street"
                :label="$t('location-dialog.street').toString()"
                required
                :error-messages="streetErrors"
                :rules="rules"
            />
            <!-- Housenumber -->
            <v-text-field
                outlined
                v-model="location.housenumber"
                :label="$t('location-dialog.housenumber').toString()"
                required
                :error-messages="housenumberErrors"
                :rules="rules"
            />
            <!-- Zip -->
            <v-text-field
                outlined
                v-model="location.zip"
                :label="$t('location-dialog.zip').toString()"
                required
                :error-messages="zipErrors"
                :rules="rules"
            />
            <!-- City -->
            <v-text-field
                outlined
                v-model="location.city"
                :label="$t('location-dialog.city').toString()"
                required
                :error-messages="cityErrors"
                :rules="rules"
            />
            <!-- Country -->
            <v-select
                outlined
                v-model="location.country"
                :items="countries"
                :label="$t('location-dialog.country').toString()"
                required
                :error-messages="countryErrors"
                :rules="rules"
            />
            <!-- Use site Address button -->
            <v-btn v-show="siteLocation.valid" @click="useSiteAddress" :disabled="submitting" class="mb-4 mr-0 font-weight-bold action-button"
                   large depressed color="primary">
              {{ $t('location-dialog.use-site-address') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:actions>

      <!-- save button -->
      <v-btn v-if="!editing"
             :disabled="submitting || !valid"
             :loading="submitting"
             large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="submit">
        <v-icon left>add</v-icon>
        {{ $t('location-dialog.save') }}
      </v-btn>

      <!-- update button -->
      <v-btn v-if="editing"
             :disabled="submitting || !valid"
             :loading="submitting"
             large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="submit">
        <v-icon left>update</v-icon>
        {{ $t('location-dialog.update') }}
      </v-btn>

      <!-- delete button -->
      <v-btn v-if="editing"
             :disabled="submitting"
             large outlined depressed
             color="primary"
             class="mt-4 ml-0 font-weight-bold action-button"
             @click="deleteLocation">
        <v-icon left>delete</v-icon>
        {{ $t('location-dialog.delete') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame.vue";

export default {
  name: "LocationDialog",
  components: {FullscreenOverlayFrame},
  props: ['data'],
  data() {
    return {
      location: {
        street: '',
        housenumber: '',
        zip: '',
        city: '',
        country: 'Deutschland'
      },
      siteLocation: {
        street: '',
        housenumber: '',
        zip: '',
        city: '',
        country: '',
        valid: false
      },
      valid: true,
      // Rules for form validation
      rules: [v => !!v || this.$t("location-dialog.required").toString()],
      streetErrors: [],
      housenumberErrors: [],
      zipErrors: [],
      cityErrors: [],
      countryErrors: [],
      countries: ['Deutschland'],
      submitting: false
    }
  },

  methods: {
    submit() {
      this.submitting = true
      // Validate form
      this.$refs.locationForm.validate()
      if (!this.valid) {
        this.submitting = false
        return
      }
      // Submit form
      // Call Geocoding API (Nominatim) to get coordinates
      // Build Url for Geocoding API
      let host = 'https://nominatim.openstreetmap.org/'
      let endpoint = 'search?q='
      let query = this.location.street + '+' + this.location.housenumber + '+' + this.location.zip + '+' + this.location.city + '+' + this.location.country
      let url = endpoint + query + '&format=jsonv2&addressdetails=1&accept-language=de'
      // Make axios request
      this.$rhRequest.sendGet(
          {
            host: host,
            endpoint: url
          },
          (response) => {
            this.checkNominatimResponse(response)
          },
          (error) => {
            console.error('Error getting coordinates:', error)
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
          }
      )
    },
    createLocation(lat, lon) {
      let timeZone = "Europe/Berlin"
      // Create Location object
      let enodeLocation = {
        name: this.location.street + ' ' + this.location.housenumber + ', ' + this.location.zip + ' ' + this.location.city + ', ' + this.location.country,
        latitude: lat,
        longitude: lon,
        timezoneName: timeZone,
      }
      this.$rhRequest.sendPost(
          {
            endpoint: 'enode/create-location',
            data: enodeLocation
          },
          () => {
            this.$root.$emit('update-locations')
            this.close()
            this.$root.bisatoast.success({
              message: this.$t('location-dialog.location-creation.success'),
              showCloseBtn: true
            })
          },
          (error) => {
            console.error('Error creating location:', error)
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
          }
      )
    },
    deleteLocation() {
      let endpoint = 'enode/delete-location?id=' + this.data.id;
      this.$rhRequest.sendDelete(
          {
            endpoint: endpoint
          },
          () => {
            this.$root.$emit('update-locations')
            this.$root.bisatoast.success({
              message: this.$t('location-dialog.location-deletion.success'),
              showCloseBtn: true
            })
            this.close()
          },
          (error) => {
            console.error(error);
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
          }
      )
    },
    updateLocation(lat, lon) {
      let endpoint = 'enode/update-location?id=' + this.data.id;
      let enodeLocation = {
        name: this.location.street + ' ' + this.location.housenumber + ', ' + this.location.zip + ' ' + this.location.city + ', ' + this.location.country,
        latitude: lat,
        longitude: lon,
        timezoneName: this.data.timezoneName,
        id: this.data.id
      }
      this.$rhRequest.sendPut(
          {
            endpoint: endpoint,
            data: enodeLocation
          },
          () => {
            this.$root.$emit('update-locations')
            this.$root.bisatoast.success({
              message: this.$t('location-dialog.location-update.success'),
              showCloseBtn: true
            })
            this.close()
          },
          (error) => {
            console.error(error);
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
          }
      )
    },
    checkNominatimResponse(response) {
      if (!response.data.length > 0) {
        console.error('No results from Nominatim')
        this.submitting = false
        this.$root.bisatoast.error({
          message: this.$t('location-dialog.geocoding.no-results'),
          showCloseBtn: true
        })
        return
      }
      this.submitting = false
      // Check if Address matches the submitted location
      let streetsMatch = response.data[0].address.road === this.location.street
      let housenumbersMatch = response.data[0].address.house_number === this.location.housenumber
      let zipsMatch = response.data[0].address.postcode === this.location.zip
      let citiesMatch = response.data[0].address.city === this.location.city
      let countriesMatch = response.data[0].address.country === this.location.country
      // If all fields match, save the coordinates
      if (streetsMatch && housenumbersMatch && zipsMatch && citiesMatch && countriesMatch) {
        if (this.editing) {
          this.updateLocation(response.data[0].lat, response.data[0].lon)
        } else {
          this.createLocation(response.data[0].lat, response.data[0].lon)
        }
      } else {
        // Build Address
        let address = response.data[0].address.road + ' ' + response.data[0].address.house_number + ', ' + response.data[0].address.postcode + ' ' + response.data[0].address.city + ', ' + response.data[0].address.country
        this.$root.bisadialog.toggle('confirmation', true, {
          text: this.$t('location-dialog.address-no-match') + ' ' + address,
          confirmLabel: this.$t('location-dialog.confirm-address'),
        })
        this.$root.$on('dialogConfirmed', () => {
          this.$root.$off('dialogConfirmed')
          this.$root.$off('dialogAborted')
          // Put correct information into the location object
          this.location.street = response.data[0].address.road
          this.location.housenumber = response.data[0].address.house_number
          this.location.zip = response.data[0].address.postcode
          this.location.city = response.data[0].address.city
          this.location.country = response.data[0].address.country
          if (this.editing) {
            this.updateLocation(response.data[0].lat, response.data[0].lon)
          } else {
            this.createLocation(response.data[0].lat, response.data[0].lon)
          }
        })
        this.$root.$on('dialogAborted', () => {
          this.$root.$off('dialogConfirmed')
          this.$root.$off('dialogAborted')
          this.$root.bisatoast.info({
            message: this.$t('location-dialog.address-no-match.info'),
            showCloseBtn: true
          })
        })
      }
    },
    /**
     * maximize dialog
     */
    maximize() {
      this.fullscreen = true
      this.$emit('maximize')
    },

    /**
     * minimize dialog
     */
    minimize() {
      this.fullscreen = false
      this.$emit('minimize')
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('location')
    },
    init() {
      // Get address components from name
      let addressComponents = this.data.name.split(', ')
      let place = addressComponents[1].split(' ')
      this.location.zip = place[0]
      this.location.city = place[1]
      this.location.country = addressComponents[2]
      let streetAndHousenumber = addressComponents[0].split(' ')
      // street may ' ' so use last element as housenumber, all other elements as street
      this.location.street = streetAndHousenumber.slice(0, streetAndHousenumber.length - 1).join(' ')
      this.location.housenumber = streetAndHousenumber[streetAndHousenumber.length - 1]
    },
    getAddress() {
      this.$rhRequest.sendGet({
        endpoint: "site/get-general-information",
      }, (response) => {
        let city = response?.data?.data?.city
        let zip = response?.data?.data?.zip
        let street = response?.data?.data?.street
        // Call Geocoding API (Nominatim) to get coordinates
        // Build Url for Geocoding API
        let host = 'https://nominatim.openstreetmap.org/'
        let endpoint = 'search?q='
        let query = street + ', ' + zip + ' ' + city
        let url = endpoint + query + '&format=jsonv2&addressdetails=1&accept-language=de'
        // Make axios request
        this.$rhRequest.sendGet(
            {
              host: host,
              endpoint: url
            },
            (response) => {
              if (!!response?.data && response?.data?.length > 0) {
                let address = response?.data[0].address
                if (address.country === 'Deutschland') {
                  this.siteLocation.street = address.road
                  this.siteLocation.housenumber = address.house_number
                  this.siteLocation.zip = address.postcode
                  this.siteLocation.city = address.city
                  this.siteLocation.country = address.country
                  this.siteLocation.valid = true
                }
              }
            },
            (error) => {
              console.error('Error getting coordinates:', error)
              this.$root.bisatoast.error({
                message: this.$t('app.generic-error'),
                showCloseBtn: true
              })
            }
        )
      }, (error) => {
        console.error(error)
      })
    },
    useSiteAddress() {
      this.location.street = this.siteLocation.street
      this.location.housenumber = this.siteLocation.housenumber
      this.location.zip = this.siteLocation.zip
      this.location.city = this.siteLocation.city
    }
  },
  mounted() {
    this.getAddress()
  },
  computed: {
    editing() {
      return !!this.data
    }
  }
}
</script>